import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
import DatePicker from 'vue2-datepicker';
import commonhelper from '@/app/utility/mixin';
import Consult from '../../internalWorkFlow/addInternalWorkflow/consult';
import commonHelper from '@/app/utility/common.helper.utility';
import PrintNfaApproval from '../addNfaApproval/printNfaApproval';
import downlaodFiles from '../addNfaApproval/downloadFiles';
import URL_UTILITY from '../../../../utility/url.utility';
import openKmAuthentication from '../../internalWorkFlow/openKmAuthentication';
import { required } from 'vuelidate/lib/validators';
import User from '../../../admin/user';
import VueCkeditor from 'vue-ckeditor5';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: 'addNfaApproval',
  props: [
    'nfaHdrId',
    'wf_inprocess_dtl_id',
    'createCopyWorkflow',
    'selectedNfaDetails'
  ],
  components: {
    ModelSelect,
    BasicSelect,
    Consult,
    User,
    DatePicker,
    downlaodFiles,
    PrintNfaApproval,
    openKmAuthentication,
    'vue-ckeditor': VueCkeditor.component
  },
  mixins: [commonhelper],
  watch: {
    downloadCurrentPage: function() {
      // this.getInventoryItemDetails();
      this.getUploadedDocDtl();
    },
    downloadPerPage: function() {
      this.downloadCurrentPage = 1;
      // this.getInventoryItemDetails();
      this.getUploadedDocDtl();
    },
    currentPage: function() {
      this.getInventoryItemDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInventoryItemDetails();
    }
  },
  data() {
    return {
      notificationSummary: null,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'consultation_comments'
        },
        {
          key: 'action_date'
        }
      ],
      editors: {
        classic: ClassicEditor
      },
      editorConfig: {
        removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed'
        ]
      },

      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      fetchFlag: false,
      showOpenKmModal: false,
      showPrintModal: false,
      showDownloadModal: false,
      showConsultModal: false,
      editMode: false,
      loader: false,
      unsubscribe: null,
      showItemModal: false,
      totalRows: null,
      downloadCurrentPage: 1,
      currentPage: 1,
      downloadPerPage: commonHelper.perPageRecord,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showTaxModal: false,
      vendorIndex: null,
      resHdrId: 0,
      vendorCommIndex: null,
      invCode: null,
      desc: null,
      searchItem: null,
      taxIndex: null,
      showAlert: false,
      isSuccess: false,
      commonVendors: [],
      responseMsg: [],
      nfaNumber: null,
      vsetCode: null,
      removePlugins: false,
      taxData: [
        {
          value: '5',
          text: '5%'
        },
        {
          value: '12',
          text: '12%'
        },
        {
          value: '5',
          text: '15%'
        },
        {
          value: '18',
          text: '18%'
        }
      ],
      taxFields: [
        {
          key: 'text',
          label: 'Tax Percentage Value'
        }
      ],
      searchItemData: [],
      searchItemFields: [
        {
          key: 'item_code'
        },
        {
          key: 'description'
        },
        {
          key: 'quantity_uom',
          label: 'Quantity UOM'
        }
      ],
      nfaHeaderId: 0,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      nfaCreator: null,
      zone: {
        value: null,
        text: null
      },
      fromDate: commonHelper.formattedDate(new Date()),
      toDate: null,
      nfaType: {
        value: null,
        text: null
      },
      nfaObj: null,
      summary: null,
      freight: {
        value: null,
        text: null
      },
      nfaStatus: 'DRAFT',
      nfa: null,
      tempVendor: null,
      itemData: [
        {
          inventory_code: null,
          inventory_item_id: null,
          description: null,
          item_spec: null,
          quantity: null,
          quantity_uom: null,
          tax_rate_uom: null,
          tax_rate: null,
          org_id: null,
          nfa_hdr_id: null,
          nfa_item_id: 0,
          vendor_details: [
            {
              new_vendor: false,
              vendor_name: null,
              vendor_id: null,
              initial_price: null,
              final_offer: null,
              last_purchase_price: null,
              saving: null,
              saving_percentage: null,
              bussiness_share: 0,
              freight: null,
              payment_term_meaning: null,
              additional_details: null,
              approval_status: false,
              payment_term_id: null,
              freight_term_id: null,
              vendor_item_dtl_id: 0,
              freightReadonly: true
            }
          ]
        }
      ],
      payment_term: {
        id: null,
        name: null
      },

      vendor_details_fields: [
        {
          key: 'approval_status',
          label: 'Recommend',
          stickyColumn: true
        },
        {
          key: 'new_vendor',
          label: 'New Vendor',
          stickyColumn: true
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'initial_price',
          label: 'Initial Offer',
          stickyColumn: true
        },
        {
          key: 'final_offer',
          stickyColumn: true
        },
        {
          key: 'last_purchase_price',
          stickyColumn: true
        },
        {
          key: 'saving',
          label: 'Saving(+) / Hike(-)',
          stickyColumn: true
        },
        {
          key: 'saving_percentage',
          label: 'Saving (+) / Hike(-) %',
          stickyColumn: true
        },
        {
          key: 'bussiness_share',
          label: 'Business Share',
          stickyColumn: true
        },
        {
          key: 'freight',
          stickyColumn: true
        },
        {
          key: 'payment_term_meaning',
          label: 'Payment Term',
          stickyColumn: true
        },
        {
          key: 'additional_details'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],
      itemFields: [
        {
          key: 'vendor',
          label: 'Vendor'
        },
        {
          key: 'inventory_code',
          label: 'Item Code'
        },
        {
          key: 'description',
          label: 'Item Description'
        },
        {
          key: 'item_spec',
          label: 'Item Specification'
        },
        {
          key: 'quantity',
          label: 'Qty Required'
        },
        {
          key: 'quantity_uom',
          label: 'Quantity UOM'
        },
        {
          key: 'tax_rate',
          label: 'Tax Percentage'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],

      vendorData: [],
      vendorFields: [
        {
          key: 'description',
          label: 'Item Description'
        },
        {
          key: 'item_code'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'final_offer',
          stickyColumn: true
        },
        {
          key: 'saving_percentage',
          label: 'Saving(+) / Hike(-) %'
        },
        {
          key: 'freight',
          stickyColumn: true
        },
        {
          key: 'payment_term_meaning',
          label: 'Payment Term'
        },
        {
          key: 'sob',
          label: 'Business Share',
          stickyColumn: true
        },
        {
          key: 'additional_details'
        }
      ],
      lastAppData: [],
      lastAppFields: [
        {
          key: 'nfa_number',
          label: 'NFA Number'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_description'
        },
        {
          key: 'last_purchase_price'
        },
        {
          key: 'nfa_zone'
        }
      ],
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      sourceApprovalObj: null,
      wfMenuId: null,
      openkmAuthToken: null,
      visibleVendorList: [],
      showApproveForward: false,
      empUserName: {
        value: null,
        text: null
      },
      email: null,
      showUserModal: false,
      comments: null,
      finalApproverId:null
    };
  },
  validations: {
    selectedLegalEntity: {
      value: { required }
    },
    zone: {
      value: { required }
    },
    nfaType: {
      value: { required }
    }
    // voidForm: {
    //   gl_date: { required },
    //   reason: { required }
    // }
  },
  mounted() {
    if (this.nfaHdrId) {
      this.getNfaDetails(this.nfaHdrId);
    } else {
      this.editMode = true;
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.wfMenuId = this.urlSummaryData.wf_menu_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'delete') {
          this.vendorData = [];
        }
        if (actionName === 'add') {
          this.fetchData();
        }
        if (actionName === 'save' && this.editMode) {
          this.responseMsg = [];
          if (
            this.nfaStatus !== 'APPROVED' &&
            this.nfaStatus !== 'INPROCESS' &&
            this.nfaStatus !== 'NOTREQUIRED'
          ) {
            if (this.nfaType.value === 'Non RC NFA') {
              const isQtyNull = this.itemData.filter(elem => !elem.quantity);
              const isQtyZero = this.itemData.filter(
                elem => elem.quantity == 0
              );
              if (isQtyNull.length) {
                alert('Please Enter Item Quantity');
              } else {
                if (isQtyZero.length) {
                  alert('Item Quantity cannot be zero');
                } else {
                  this.addEditSourcingApprovalForHeader();
                }
              }
            } else {
              this.addEditSourcingApprovalForHeader();
            }
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (this.urlSummaryData) {
      this.getNfaDetails(this.urlSummaryData.nfa_hdr_id);
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.nfaCreator = this.getUserName();
      this.getEventId();
    }
  },
  methods: {
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    getNfaDetails(nfaHdrId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getNfaHdrDetails', nfaHdrId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const results = resp.data.data;
            this.sourceApprovalObj = results;
            this.nfaStatus = this.createCopyWorkflow
              ? 'DRAFT'
              : results.approval_status;
            this.nfaCreator = results.created_by;
            this.fromDate = this.createCopyWorkflow? commonHelper.formattedDate(new Date()) :results.creation_date;
            this.toDate = this.createCopyWorkflow? null:results.end_date;
            this.selectedLegalEntity.text = results.le_name;
            this.selectedLegalEntity.value = results.le_id;
            this.resHdrId = this.createCopyWorkflow ? 0 : results.nfa_hdr_id;
            this.nfaNumber = this.createCopyWorkflow
              ? null
              : results.nfa_number;
            this.nfaObj = results.nfa_objective;
            this.comments = results.short_close_reason;
            this.summary = results.nfa_summary;
            this.nfaType.text = results.nfa_type_meaning;
            this.nfaType.value = results.nfa_type_vset;
            this.zone.text = results.nfa_zone;
            this.nfa = results.approval_note;
            if (results.item_lists.length > 0) {
              this.itemData = results.item_lists;
              this.itemData.forEach(elem => {
                this.getVendorDetailsById(elem);
                if (this.createCopyWorkflow) {
                  elem.nfa_item_id = 0;
                }
              });
            }
            if (!this.createCopyWorkflow) {
              this.getFetchItemDetails();
            }
            this.getApprovedNfaPrice();
            if (this.nfaStatus !== 'DRAFT') {
              this.getApprovalHistory(this.resHdrId);
            }
            if (
              this.$route.name === 'notificationSummary' ||
              this.$route.name === 'dashboard' ||
              this.$route.name === 'workFlowSummary'
            ) {
              this.notificationSummary = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
      // }
    },
    addEditSourcingApprovalForHeader() {
      const payload = {
        nfa_hdr_id: this.resHdrId,
        le_id: this.selectedLegalEntity.value,
        nfa_zone: this.zone.text,
        end_date: this.toDate,
        nfa_type_vset: this.nfaType.value,
        nfa_objective: this.nfaObj,
        nfa_summary: this.summary,
        approval_note: this.nfa
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditSourcingApprovalForHeader', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.resHdrId = response.data.data.nfa_hdr_id;
            this.nfaNumber = response.data.data.nfa_number;
            this.nfaStatus = response.data.data.approval_status;
            const uploadDocObj = {
              ...response.data.data,
              approval_note: this.nfa,
              created_by: this.nfaCreator,
              creation_date: this.fromDate,
              end_date: this.toDate,
              le_id: this.selectedLegalEntity.value,
              le_name: this.selectedLegalEntity.text,
              nfa_objective: this.nfaObj,
              nfa_summary: this.summary,
              nfa_type_meaning: this.nfaType.text,
              nfa_type_vset: this.nfaType.value,
              nfa_zone: this.zone.text
            };
            this.$emit('getNfaHdrId', uploadDocObj);
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .then(() => {
          this.addEditSourcingApprovalForDetails();
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(appStrings.autoFailedMsg);
        });
    },
    addEditSourcingApprovalForDetails() {
      const filterItemData = this.itemData.filter(
        data => data.inventory_item_id > 0
      );
      const itemDetails = filterItemData.map(elem => {
        return {
          nfa_item_id: elem.nfa_item_id,
          inventory_item_id: elem.inventory_item_id,
          item_spec: elem.item_spec,
          quantity: elem.quantity,
          tax_rate: elem.tax_rate,
          data: elem.vendor_details.map(dtl => {
            return {
              vendor_item_dtl_id: dtl.vendor_item_dtl_id,
              vendor_id: dtl.vendor_id,
              new_vendor: dtl.new_vendor,
              vendor_name: dtl.vendor_name,
              initial_price: dtl.initial_price,
              final_offer: dtl.final_offer,
              last_purchase_price: dtl.last_purchase_price,
              bussiness_share: dtl.bussiness_share,
              freight: dtl.freight,
              payment_term_id: dtl.payment_term_id,
              freight_term_id: dtl.freight_term_id,
              additional_details: dtl.additional_details,
              approval_status: dtl.approval_status
            };
          })
        };
      });
      const payload = {
        nfa_hdr_id: this.resHdrId,
        item_lists: itemDetails ? itemDetails : []
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditSourcingApprovalForDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.getFetchItemDetails();
            this.getApprovedNfaPrice();
            this.visibleVendorList.forEach(elem => {
              this.showVendor(elem);
            });

            if (results.item_lists.length > 0) {
              this.itemData = results.item_lists;
              this.itemData.forEach(elem => {
                this.getVendorDetailsById(elem);
              });
            }
            this.fetchFlag = true;
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(appStrings.autoFailedMsg);
        });
    },
    deleteItemsVendors(itemList) {
      const payload = {
        nfa_hdr_id: this.resHdrId,
        item_lists: itemList
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/deleteItemsVendors', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.nfaStatus = results.approval_status;
            if (results.item_lists.length > 0) {
              this.itemData = results.item_lists;
              this.itemData.forEach(elem => {
                this.getVendorDetailsById(elem);
              });
            } else {
              this.itemData = results.item_lists;
              this.itemData = [
                {
                  inventory_code: null,
                  inventory_item_id: null,
                  description: null,
                  item_spec: null,
                  quantity: null,
                  quantity_uom: null,
                  tax_rate_uom: null,
                  tax_rate: null,
                  org_id: null,
                  nfa_hdr_id: null,
                  nfa_item_id: 0,
                  vendor_details: [
                    {
                      vendor_name: null,
                      vendor_id: null,
                      new_vendor: false,
                      initial_price: null,
                      final_offer: null,
                      last_purchase_price: null,
                      saving: null,
                      saving_percentage: null,
                      bussiness_share: 0,
                      freight: null,
                      payment_term_id: null,
                      freight_term_id: null,
                      payment_term_meaning: null,
                      approval_status: false,
                      additional_details: null,
                      vendor_item_dtl_id: 0,
                      freightReadonly: true
                    }
                  ]
                }
              ];
            }
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .then(() => {
          this.getFetchItemDetails();
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(appStrings.autoFailedMsg);
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData.wf_menu_id,
        sub_menu_id: 0,
        record_id: this.nfaHdrId
          ? this.nfaHdrId
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getInventoryItemDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        inventory_code: this.invCode,
        description: this.desc
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getInventoryItemDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.page;
            this.searchItemData = results;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorDetailsById(item) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorDetailsById', item.nfa_item_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            if (results.length > 0) {
              const data = results.map(key => {
                key.vendor_item_dtl_id =
                  this.createCopyWorkflow && item.nfa_item_id !== 0
                    ? key.vendor_item_dtl_id
                    : item.nfa_item_id !== 0
                    ? key.vendor_item_dtl_id
                    : 0;

                key.freightReadonly = true;
                return key;
              });
              item.vendor_details = data;
            } else {
              item.vendor_details = [
                {
                  vendor_item_dtl_id: 0,
                  vendor_name: null,
                  vendor_id: null,
                  new_vendor: false,
                  initial_price: null,
                  final_offer: null,
                  last_purchase_price: null,
                  saving: null,
                  saving_percentage: null,
                  bussiness_share: 0,
                  freight: null,
                  payment_term_meaning: null,
                  payment_term_id: null,
                  approval_status: false,
                  freightReadonly: true
                }
              ];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getFetchItemDetails() {
      const payload = {
        // recommended: this.$route.name !== 'nfaApp' ? 'yes' : 'no',
        nfa_hdr_id: this.resHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getFetchItemDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.vendorData = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovedNfaPrice() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getApprovedNfaPrice', this.resHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.lastAppData = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showVendor(items) {
      if (!items.detailsShowing) {
        this.visibleVendorList.push(items);
      }
      items.toggleDetails();
    },
    itemCollapseRow(item) {
      if (item.detailsShowing) {
        item.toggleDetails();
      }
    },
    clearVendor(itemIndex, vendorIndex) {
      this.itemData[itemIndex].vendor_details[vendorIndex].vendor_name = null;
      this.itemData[itemIndex].vendor_details[vendorIndex].vendor_id = null;
    },
    validateSharingRatio(vendorRow, itemIndex) {
      if (typeof vendorRow.bussiness_share !== 'number') {
        vendorRow.bussiness_share = 0;
      }
      let sum = 0;
      this.itemData[itemIndex].vendor_details.forEach(item => {
        sum += parseFloat(item.bussiness_share);
      });
      if (sum > 100) {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg.push('Business Share cannot be greater than 100!');
        sum = 0;
        vendorRow.bussiness_share = 0;
      }
    },
    closeValueSetModal() {
      // To close valueset modal
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, itemIndex, vendorIndex) {
      // To open valueset modal
      this.vsetCode = vsetCode;
      this.vendorIndex = vendorIndex;
      this.itemIndex = itemIndex;
      if (
        this.vsetCode === 'EXTERNAL_VENDORS' &&
        this.itemData[itemIndex].vendor_details[vendorIndex].new_vendor === true
      ) {
        return;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // To select valueset
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
      } else if (this.vsetCode === 'ZONE') {
        this.zone.value = item.value_code;
        this.zone.text = item.value_meaning;
      } else if (this.vsetCode === 'SOURCING_NFA_TYPE') {
        this.nfaType.value = item.value_code;
        this.nfaType.text = item.value_meaning;
      } else if (this.vsetCode === 'INV_ITEM_CODE') {
        this.itemData[this.itemIndex].inventory_item_id = item.value_set_dtl_id;
        this.itemData[this.itemIndex].inventory_code = item.value_code;
        this.itemData[this.itemIndex].description = item.description;
        this.itemData[this.itemIndex].quantity_uom = item.primary_uom_code;
      } else if (this.vsetCode === 'TAX_VALUES') {
        this.itemData[this.itemIndex].tax_rate_uom = item.value_meaning;
        this.itemData[this.itemIndex].tax_rate = item.value_code;
      } else if (this.vsetCode === 'EXTERNAL_VENDORS') {
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].vendor_name = item.value_code;
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].vendor_id = item.value_set_dtl_id;
      } else if (this.vsetCode === 'SOURCING_APP_FREIGHT') {
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].freightReadonly = item.value_code === 'Others' ? false : true;
        this.itemData[this.itemIndex].vendor_details[this.vendorIndex].freight =
          item.value_code === 'Others' ? null : item.value_code;
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].freightReadonly = item.value_code === 'Others' ? false : true;
        this.itemData[this.itemIndex].vendor_details[this.vendorIndex].freight =
          item.value_code === 'Others' ? null : item.value_code;
        this.freight = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].freight = this.freight.text;
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].freight_term_id = this.freight.value;
        this.addDataInFreight(this.vendorIndex, this.itemIndex);
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.payment_term = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].payment_term_meaning = this.payment_term.name;
        this.itemData[this.itemIndex].vendor_details[
          this.vendorIndex
        ].payment_term_id = this.payment_term.id;
        this.savingCalculate(this.vendorIndex, this.itemIndex);
      }
      this.showValueSetModal = false;
    },
    setToday(date) {
      if (this.startDate(date)) {
        return date;
      }
    },
    savingCalculate(vendorIndex, itemIndex) {
      let approvalItem = this.itemData[itemIndex].vendor_details[vendorIndex];
      if (
        approvalItem.initial_price &&
        approvalItem.final_offer &&
        approvalItem.last_purchase_price
      ) {
        this.itemData[itemIndex].vendor_details[vendorIndex].saving = (
          this.itemData[itemIndex].vendor_details[vendorIndex]
            .last_purchase_price -
          this.itemData[itemIndex].vendor_details[vendorIndex].final_offer
        ).toFixed(2);

        this.itemData[itemIndex].vendor_details[vendorIndex].saving_percentage =
          (this.itemData[itemIndex].vendor_details[vendorIndex].saving /
            this.itemData[itemIndex].vendor_details[vendorIndex]
              .last_purchase_price) *
          100;
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].saving_percentage = this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].saving_percentage.toFixed(2);
      } else if (approvalItem.initial_price === '') {
        alert("Initial Price can't be empty");
        approvalItem.saving = null;
        approvalItem.saving_percentage = null;
        approvalItem.final_offer = null;
        approvalItem.last_purchase_price = null;
      } else if (approvalItem.final_offer === '') {
        alert("Final offer can't be empty");
        approvalItem.saving = null;
        approvalItem.saving_percentage = null;
      } else if (approvalItem.last_purchase_price === '') {
        alert("Last Purchase Price can't be empty");
        approvalItem.saving = null;
        approvalItem.saving_percentage = null;
      }
      this.itemData[itemIndex].vendor_details.forEach(key => {
        if (
          key.last_purchase_price === null ||
          key.last_purchase_price === ''
        ) {
          key.last_purchase_price = this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].last_purchase_price;
        }
      });
      this.itemData[itemIndex].vendor_details.forEach(key => {
        if (
          key.payment_term_meaning === null ||
          key.payment_term_meaning === ''
        ) {
          key.payment_term_meaning = this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].payment_term_meaning;
          key.payment_term_id = this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].payment_term_id;
        }
      });
    },

    addDataInFreight(vendorIndex, itemIndex) {
      this.itemData[itemIndex].vendor_details.forEach(key => {
        if (key.freight === null || key.freight === '') {
          key.freight = this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].freight;
          key.freight_term_id = this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].freight_term_id;
        }
      });
    },

    showHidePrint(flag) {
      this.showPrintModal = flag;
    },
    taxModal(flag, index) {
      this.taxIndex = index;
      this.showTaxModal = flag;
    },
    selectedTaxPercentage(item) {
      this.itemData[this.taxIndex].tax_meaning = item.text;
      this.itemData[this.taxIndex].tax_id = item.value;
      this.showTaxModal = false;
    },
    filteredEventList() {
      if (undefined === this.searchItem || this.searchItem === '')
        return this.unfilteredSubUnitList;
      return this.unfilteredSubUnitList.filter(product => {
        let flag;
        for (let prop in product) {
          flag = false;
          flag = product[prop].toString().indexOf(this.searchItem) > -1;
          if (flag) break;
        }
        return flag;
      });
    },
    openCloseModal(flag, itemIndex) {
      this.vendorIndex = itemIndex;
      this.showItemModal = flag;
      this.getInventoryItemDetails();
    },
    selectedItem(item) {
      this.itemData[this.vendorIndex].inventory_item_id =
        item.inventory_item_id;
      this.itemData[this.vendorIndex].inventory_code = item.item_code;
      this.itemData[this.vendorIndex].description = item.description;
      this.itemData[this.vendorIndex].quantity_uom = item.quantity_uom;
      this.showItemModal = false;
    },
    clearItem() {
      this.invCode = null;
      this.desc = null;
      this.getInventoryItemDetails();
    },
    async printIntWorkflow() {
      await this.$htmlToPaper('print-workflow', {
        windowTitle: `DPL SA ${new Date().toString().slice(0, 23)}`
      });
    },
    showHideConsult(flag) {
      this.showConsultModal = flag;
    },
    ItemAddRow(itemIndex) {
      if (
        this.nfaStatus !== 'INPROCESS' &&
        this.nfaStatus !== 'APPROVED' &&
        this.nfaStatus !== 'NOTREQUIRED' &&
        this.editMode
      ) {
        this.itemData.push({
          inventory_code: null,
          inventory_item_id: null,
          description: null,
          item_spec: null,
          quantity: null,
          quantity_uom: null,
          tax_rate_uom: null,
          tax_rate: null,
          org_id: null,
          nfa_hdr_id: null,
          nfa_item_id: 0,
          vendor_details: this.itemData[itemIndex].vendor_details.map(elem => {
            return {
              approval_status: false,
              new_vendor: elem.new_vendor,
              vendor_name: elem.vendor_name,
              vendor_id: elem.vendor_id,
              initial_price: null,
              final_offer: null,
              last_purchase_price: null,
              saving: null,
              saving_percentage: null,
              bussiness_share: 0,
              freight: null,
              freight_term_id: null,
              payment_term_id: null,
              payment_term_meaning: null,
              additional_details: null,
              vendor_item_dtl_id: 0,
              freightReadonly: true
            };
          })
        });
      } else {
        alert('You cannot add at this stage');
      }
    },
    itemRemoveRow(index) {
      const promt = confirm('Are you sure, you want to delete?');
      if (promt) {
        if (
          this.nfaStatus !== 'INPROCESS' &&
          this.nfaStatus !== 'APPROVED' &&
          this.nfaStatus !== 'NOTREQUIRED'
          // &&
          // this.editMode
        ) {
          if (this.itemData[index].nfa_item_id === 0) {
            this.itemData.splice(index, 1);
          } else {
            const itemList = [
              {
                nfa_item_id: this.itemData[index].nfa_item_id,
                data: [
                  {
                    vendor_item_dtl_id: null
                  }
                ]
              }
            ];
            this.deleteItemsVendors(itemList);
          }
        } else {
          alert('You cannot remove at this stage');
        }
      }
    },
    vendorAddRow(item, itemIndex, vendorIndex) {
      if (
        this.nfaStatus !== 'INPROCESS' &&
        this.nfaStatus !== 'APPROVED' &&
        this.nfaStatus !== 'NOTREQUIRED' &&
        this.editMode
      ) {
        this.itemData[itemIndex].vendor_details.push({
          vendor_item_dtl_id: 0,
          vendor_name: null,
          vendor_id: null,
          new_vendor: false,
          initial_price: null,
          final_offer: null,
          last_purchase_price: this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].last_purchase_price,
          saving: null,
          saving_percentage: null,
          bussiness_share: 0,
          freight: this.itemData[itemIndex].vendor_details[vendorIndex].freight,
          freight_term_id: this.itemData[itemIndex].vendor_details[vendorIndex]
            .freight_term_id,
          payment_term_meaning: this.itemData[itemIndex].vendor_details[
            vendorIndex
          ].payment_term_meaning,
          payment_term_id: this.itemData[itemIndex].vendor_details[vendorIndex]
            .payment_term_id,
          approval_status: false,
          freightReadonly:
            item.freight === 'Included' ||
            item.freight === 'Extra' ||
            item.freight === 'At Actual'
              ? true
              : false
        });
      } else {
        alert('You cannot add at this stage');
      }
      this.itemData = JSON.parse(JSON.stringify(this.itemData));
    },
    vendorRemoveRow(vendorIndex, itemIndex) {
      const promt = confirm('Are you sure, you want to delete?');
      if (promt) {
        if (
          this.nfaStatus !== 'INPROCESS' &&
          this.nfaStatus !== 'APPROVED' &&
          this.nfaStatus !== 'NOTREQUIRED'
          // &&
          // this.editMode
        ) {
          if (
            this.itemData[itemIndex].vendor_details[vendorIndex]
              .vendor_item_dtl_id === 0
          ) {
            this.itemData[itemIndex].vendor_details.splice(vendorIndex, 1);
          } else {
            const itemList = [
              {
                nfa_item_id: null,
                data: [
                  {
                    vendor_item_dtl_id: this.itemData[itemIndex].vendor_details[
                      vendorIndex
                    ].vendor_item_dtl_id
                  }
                ]
              }
            ];
            this.deleteItemsVendors(itemList);
          }
        } else {
          alert('You cannot remove at this stage');
        }
      }
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    },
    getApprovalHistory(nfa_hdr_id) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            nfaHdrId: nfa_hdr_id,
            approvalStatus: 'Y',
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    user.approval_status === 'INPROCESS'
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data =>
                    data.approver_employee_id === this.userInfo.employee_id &&
                    data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.resHdrId);
            this.editMode = false;
            this.isSuccess = true;
            const result = response.data.data;
            result.forEach(key => {
              this.responseMsg.push(key.message);
            });
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    showHideApproveForwardModal(flag) {
      this.showApproveForward = flag;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.empUserName.text = item.name;
      this.empUserName.value = item.user_id;
      this.approverId = item.user_ref_id; 
      const numberValue = parseInt(this.approverId);
      const result = [{ approver_id: numberValue }];
      this.finalApproverId = result
      this.email = item.user_email;
      this.showUserModal = false;
    },
    approveForwardApproval() {
      const payload = {
        // approver_id: this.approverId,
        approver_ids: this.finalApproverId,
        event_id: this.urlSummaryData ? this.urlSummaryData.wf_event_id : this.eventId,
        inprocess_dtl_id: this.wfDtlId,
        inprocess_hdr_id: this.getWfInprocessHdrId(),
        notification_comments: this.notificationComments
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approveForwardApproval', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
            this.showApproveForward = false;
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
            this.showApproveForward = false;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(appStrings.autoFailedMsg);
        });
    },
    getWfInprocessHdrId() {
      const findCurrentApprover = this.approvalHistoryList.find(
        user => user.approver_employee_id === this.userInfo.employee_id
      );
      return findCurrentApprover.wf_inprocess_hdr_id;
    },
    clearVsetValues(vsetCode, itemIndex, vendorIndex) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      } else if (vsetCode === 'ZONE') {
        this.zone.value = null;
        this.zone.text = null;
      } else if (vsetCode === 'SOURCING_NFA_TYPE') {
        this.nfaType.value = null;
        this.nfaType.text = null;
      } else if (vsetCode === 'INV_ITEM_CODE') {
        this.itemData[itemIndex].inventory_item_id = null;
        this.itemData[itemIndex].inventory_code = null;
        this.itemData[itemIndex].description = null;
        this.itemData[itemIndex].quantity_uom = null;
      } else if (vsetCode === 'TAX_VALUES') {
        this.itemData[itemIndex].tax_rate_uom = null;
        this.itemData[itemIndex].tax_rate = null;
      } else if (vsetCode === 'EXTERNAL_VENDORS') {
        this.itemData[itemIndex].vendor_details[vendorIndex].vendor_name = null;
        this.itemData[itemIndex].vendor_details[vendorIndex].vendor_id = null;
      } else if (vsetCode === 'SOURCING_APP_FREIGHT') {
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].freightReadonly = null;
        this.itemData[itemIndex].vendor_details[vendorIndex].freight = null;
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].freightReadonly = null;
        this.itemData[itemIndex].vendor_details[vendorIndex].freight = null;
        this.freight = {
          value: null,
          text: null
        };
        this.itemData[itemIndex].vendor_details[vendorIndex].freight = null;
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].freight_term_id = null;
      } else if (vsetCode === 'FMS_PAYMENT_TERM') {
        this.payment_term = {
          id: null,
          name: null
        };
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].payment_term_meaning = null;
        this.itemData[itemIndex].vendor_details[
          vendorIndex
        ].payment_term_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};